function criteriaToQueryParams(criteria) {
    const params = Object.keys(criteria);
    var queryParams = "";
    params.forEach(function(param) {
        var paramToAdd = param;
        var valuesToAdd = [criteria[param]];
        if (param == "dsps") {
            paramToAdd = "dsp";
            valuesToAdd = criteria[param];
        }
        if (param == "useType") {
            valuesToAdd = criteria[param];
        }
        valuesToAdd.forEach(value => {
            if (queryParams == "") queryParams = queryParams + "?"
            else queryParams = queryParams + "&"
            queryParams = queryParams + paramToAdd + "=" + value
        } )
    });

    return queryParams;
}

const MAX_EXCEL_ROWS = 250000
const MAX_TSV_ROWS = 7000000
;
define("main", function(){});

